<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="10">
          <v-card class="elevation-12">
            <ScriptToolbar :title="$t('script.name')" />
            <router-view></router-view>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ScriptToolbar from "@/components/script/ScriptToolbar.vue";

export default {
  name: "ScriptView",
  components: { ScriptToolbar }
};
</script>
